<header id="header" class="sub-header" [ngClass]="isDropdown ? 'active' : ''">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler isMobile"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar"
          aria-controls="navbar"
          aria-label="Toggle navigation"
          (click)="toggle()"
        >
          <span> </span>
          <span> </span>
          <span> </span>
        </button>
        <a class="navbar-brand" [routerLink]="Constant.url_home"
          ><img
            src="https://fma.dev.techland.link/trade/assets/images/logo/fmarket-blue.png"
            alt="fmarket"
            width="128"
            loading="lazy"
        /></a>
        <div class="collapse navbar-collapse" id="navbar">
          <ul class="navbar-nav me-auto mb-lg-0">
            <li class="nav-item" id="menuHome">
              <a
                class="nav-link"
                [routerLink]="Constant.url_home"
                [ngClass]="MENUS_ACTIVE.HOME ? 'active-link': ''"
                (click)="menuService.hideDashboard('#menuHome')"
              >
                {{'Trang chủ'}}
              </a>
            </li>
            <li class="nav-item dropdown" id="menuProduct">
              <div class="flex-between">
                <span
                  [ngClass]="MENUS_ACTIVE.PROCDUCT ? 'active-link': ''"
                  class="nav-link"
                >
                  Sản phẩm
                </span>
                <button class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block w-full h-full"
                  >
                    <path
                      d="M8 10l3.293 3.293a1 1 0 001.414 0L16 10"
                      stroke="#32302F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>

              <ul class="dropdown-menu">
                <!-- <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_landing_page_product"
                    (click)="menuService.hideDashboard('#menuProduct')"
                    >{{'Quỹ mở là gì?'}}</a
                  >
                </li> -->
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_funds_page"
                    (click)="menuService.hideDashboard('#menuProduct')"
                    >{{'Chứng chỉ quỹ'}}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_gold_home"
                    (click)="menuService.hideDashboard('#menuProduct')"
                    >{{'Vàng'}}</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown" id="menuMarket">
              <div class="flex-between">
                <span
                  [ngClass]="MENUS_ACTIVE.MARKET ? 'active-link': ''"
                  class="nav-link "
                  id="navbarDropdown3">
                  Thị trường </span
                ><button class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block w-full h-full"
                  >
                    <path
                      d="M8 10l3.293 3.293a1 1 0 001.414 0L16 10"
                      stroke="#32302F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>

              <ul class="dropdown-menu" aria-labelledby="navbarDropdown3">
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_bank_interest_rate"
                    (click)="menuService.hideDashboard('#menuMarket')"
                    >{{'Lãi suất ngân hàng'}}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_performance_fund"
                    (click)="menuService.hideDashboard('#menuMarket')"
                    >{{'Hiệu suất quỹ mở'}}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_gold_chart_dashboard"
                    (click)="menuService.hideDashboard('#menuMarket')"
                    >{{'Giá vàng hôm nay'}}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_ftool"
                    (click)="menuService.hideDashboard('#menuMarket')"
                    >{{'Ftool - Công cụ tính lãi kép'}}</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown" id="menuBlog">
              <div class="flex-between">
                <a
                  [ngClass]="MENUS_ACTIVE.BLOG ? 'active-link': ''"
                  class="nav-link "
                  id="navbarDropdown4"
                  [routerLink]="Constant.url_blog"
                  (click)="menuService.hideDashboard('#menuBlog')"
                >
                  Kiến thức
                </a>
                <button class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block w-full h-full"
                  >
                    <path
                      d="M8 10l3.293 3.293a1 1 0 001.414 0L16 10"
                      stroke="#32302F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown4">
                <li *ngFor="let item of categories">
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_blog_category_on_header + '/' + item.path"
                    (click)="menuService.hideDashboard('#menuBlog')"
                    >{{item.name}}</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown" id="menuAbout">
              <div class="flex-between">
                <span
                  [ngClass]="MENUS_ACTIVE.ABOUT ? 'active-link': ''"
                  class="nav-link "
                  id="navbarDropdown5"
                >
                  Về chúng tôi </span
                ><button class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block w-full h-full"
                  >
                    <path
                      d="M8 10l3.293 3.293a1 1 0 001.414 0L16 10"
                      stroke="#32302F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>

              <ul class="dropdown-menu" aria-labelledby="navbarDropdown5">
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_our_story"
                    (click)="menuService.hideDashboard('#menuAbout')"
                    >{{'Giới thiệu Fmarket'}}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_our_partner"
                    (click)="menuService.hideDashboard('#menuAbout')"
                    >{{'Đối tác'}}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_blog_category_partner"
                    (click)="menuService.hideDashboard('#menuBlog')"
                    >Hoạt động doanh nghiệp</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item nav-children"
                    [routerLink]="Constant.url_fqs"
                    (click)="menuService.hideDashboard('#menuAbout')"
                    >{{'Trợ giúp'}}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <ul id="nav-authen">
          <li>
            <a class="nav-link btn-border" [href]="Constant.url_login"> {{'Đăng nhập'}} </a>
          </li>
          <li>
            <a class="btn-login nav-link" [href]="Constant.url_register_investor"> {{'Đăng ký'}} </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
